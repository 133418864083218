import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Project = ({
  description,
  title,
  subtitle,
  github,
  stack,
  web,
  image,
  index,
  slug,
}) => {
  return (
    <article className="project">
      <div className="project-img-container">
        <div className="project-img">
          <GatsbyImage image={getImage(image)} alt={title} />
        </div>
      </div>
      <div className="project-info">
        <span className="project-number">{index + 1}.</span>

        <h2>{title}</h2>
        <h4>{subtitle}</h4>

        <p className="project-desc">{description}</p>
        <div className="project-stack">
          {stack.map(item => {
            return <p key={item.id}>{item.title}</p>
          })}
        </div>
        <Link to={`/portfolio/${slug}`} className="btn">
          Ver Proyecto
        </Link>
        {web && (
          <a href={web} target="_blank" className="btn">
            Ir al sitio
          </a>
        )}
      </div>
    </article>
  )
}

export default Project
