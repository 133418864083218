import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Title from "./Title"
import Project from "./Project"
import { Link } from "gatsby"
import ReactFullpage from "@fullpage/react-fullpage"
import { FaChevronDown } from "react-icons/fa"
import BackgroundImage from "gatsby-background-image"
import Contact from "../components/Contact"

const Projects = ({ projects, title }) => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "bg-portfolio.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920)  {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    }
  `)

  return (
    <ReactFullpage
      licenseKey = {"YOUR_KEY_HERE"}
      scrollingSpeed = {500}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <header className="section hero section-center bg-cover" id="hero">
              <BackgroundImage fluid={data.file.childImageSharp.fluid} className="hero-cover">
                <div className="title-container text-center">
                  <h1>
                    <span className="text-black text-bold">Portfolio</span>
                    <span className="h1-subtitle">Algunos trabajos</span>
                  </h1>
                </div>
                <div
                  className="arrow arrow-down"
                  onClick={() => fullpageApi.moveSectionDown()}
                >
                  <FaChevronDown />
                </div>
              </BackgroundImage>
            </header>

            {projects.map((project, index) => {
              return (
                <div className="section project-item">
                  <Project key={project.id} index={index} {...project} />
                </div>
              )
            })}
                    <Contact className="section" />
            
            <div
              className="arrow arrow-top"
              onClick={() => fullpageApi.moveTo(1)}
            >
              <FaChevronDown />
            </div>
          </ReactFullpage.Wrapper>
        )
      }}
    />
  )
}

export default Projects
